
export default {
    drawer: true, 
    responsive: false,
  
   // systemlogo: 'file:///android_asset/www/logo.png',
   //applogo: 'file:///android_asset/www/logo.png',
    systemlogo: '../img/mylogo.png',
   applogo: '../img/mylogo.png',
   gcashlogo: '../img/gcash.png',
   check: '../img/check.png',
    is_logged: false,
    is_online: false,
    show_alert: false,
    overlay_loading: false,
    overlay_loading_text: '',
    alert_color: "secondary",
    alert_text: "No message",
    results:{},
    trucks:[],
    users:[],
    last_update:'',
    dr_number:'',
    uploaded_date:'',
    dr_date:'',
    balance:{},
   // games: ['All Games', '2D','3D', '2D-STL-ILOILO-CITY', '3D-STL-ILOILO-CITY','STL-SILAY','STL-ILOILO-PROVINCE'],
   //game_seq: ['2D','3D', '2D-STL-ILOILO-CITY', '3D-STL-ILOILO-CITY','STL-SILAY','STL-ILOILO-PROVINCE'],
    games: ['All Games','STL', '3D'],
    game_seq: ['STL', '3D'],
    customers:[],
    open_3d_draws:[],
    open_2d_draws:[],
    account_summary:{},
    account:{},
    bettor_name:"",
    required_update: false
  }
  